import axios from '@axios'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchList(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get(
            `${process.env.VUE_APP_ASYNC_BACKEND_SERVER}/point/exchange/list/adm/`,
            { params: queryParams, headers: {"X-Api-Version": "v2"} },
          )
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    approveExchange(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .post(
            `${process.env.VUE_APP_ASYNC_BACKEND_SERVER}/point/exchange/adm/`,
            params,
            { headers: {"X-Api-Version": "v2"} },
          )
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    cancelExchange(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .delete(
            `${process.env.VUE_APP_ASYNC_BACKEND_SERVER}/point/exchange/adm/`,
            { params: queryParams, headers: {"X-Api-Version": "v2"} },
          )
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    excelExchange(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get(
            `${process.env.VUE_APP_ASYNC_BACKEND_SERVER}/point/exchange/excel/adm/`,
            { params: queryParams, responseType: 'blob', headers: {"X-Api-Version": "v2"} },
          )
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
  },
}
