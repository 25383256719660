<template>
  <div>
    <tagby-filter
      :categoryOptions="categoryOptions"
      :initial-search-params="initialSearchParams"
      @search="search"
    >
      <template #buttons>
        <b-button
          variant="outline-success"
          class="mr-1"
          @click="downloadExcelPointExchange"
        >
          <span class="align-middle">엑셀 다운로드</span>
        </b-button>
        <b-button
          variant="outline-primary"
          class="mr-1"
          @click="turnOnManyApproveModal"
        >
          <span class="align-middle">선택 승인</span>
        </b-button>
      </template>
    </tagby-filter>
    <tagby-list
      v-model="currentPage"
      :items="items"
      :fields="tableColumns"
      :busy="isBusy"
      :current-page="currentPage"
      :total-rows="totalRows"
      :per-page="perPage"
      :selectable="true"
      @page-change="changePage"
      @row-selected="onRowSelected"
    >
      <template #cell(idx)="data">
        {{ data.item.idx }}
      </template>

      <template #cell(state)="data">
        {{ resolveState(data.item) }}
      </template>

      <template #cell(action)="data">
        <div
          v-if="['APPLY', 'DONE'].includes(data.item.state)"
          class="d-flex"
        >
          <b-button
            class="mr-1"
            size="sm"
            variant="outline-danger"
            @click="turnOnCancelModal(data.item.idx)"
          >
            취소
          </b-button>
          <b-button
            v-if="data.item.state === 'APPLY'"
            size="sm"
            variant="outline-primary"
            @click="turnOnApproveModal(data.item.idx)"
          >
            승인
          </b-button>
        </div>
      </template>

      <template #cell(created_at)="data">
        <div style="max-width: 90px;">
          {{ data.item.created_at }}
        </div>
      </template>

      <template #cell(exchange_at)="data">
        <div style="max-width: 90px;">
          {{ data.item.exchange_at }}
        </div>
      </template>
    </tagby-list>

    <approve-modal
      :visible="visibleApproveModal"
      @ok="approvePointExchange(selectedIdxList)"
      @hidden="turnOffApproveModal"
    />

    <cancel-modal
      :visible="visibleCancelModal"
      @ok="cancelPointExchange(selectedIdxList)"
      @hidden="turnOffCancelModal"
    />
  </div>
</template>

<script>
import {
  BLink,
  BButton,
} from 'bootstrap-vue'
import ViewModel from './ViewModel'
import TagbyFilter from '@/components/TagbyFilter.vue'
import TagbyList from '@/components/TagbyList.vue'
import ApproveModal from './components/ApproveModal.vue'
import CancelModal from './components/CancelModal.vue'

export default {
  components: {
    BLink,
    BButton,
    TagbyFilter,
    TagbyList,
    ApproveModal,
    CancelModal,
  },
  setup() {
    const resolveState = item => ({
      APPLY: '대기',
      DONE: '완료',
      CANCEL: '취소',
      FAIL: '실패',
    }[item.state])

    return {
      resolveState,
      ...ViewModel(),
    }
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>
