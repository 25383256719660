<template>
  <b-modal
    :visible="visible"
    ok-title="승인"
    ok-variant="primary"
    cancel-title="닫기"
    cancel-variant="outline-secondary"
    modal-class="modal-primary"
    centered
    title="승인"
    @ok="$emit('ok')"
    @hidden="$emit('hidden')"
  >
    포인트 출금을 승인하시겠습니까?
  </b-modal>
</template>

<script>
import {
  BModal,
  VBModal,
} from 'bootstrap-vue'

export default {
  components: {
    BModal,
  },
  directives: {
    'b-modal': VBModal,
  },
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
  },
  emits: [
    'ok', 'hidden',
  ],
}
</script>
